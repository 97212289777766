import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import thumbnail from '../images/Thumbnail_StudioMidos.png'

interface Props {
  description: string
  lang: string
  meta?: []
  title: string
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const titleToArray = [...title]
  const lastCharacter = titleToArray.length - 1
  const SeoTitle =
    titleToArray[lastCharacter] === '.' ? title.slice(0, -1) : title

    const fullPathThumbnail =
      'https://www.studiomidos.com' + thumbnail
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={SeoTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `image`,
          content: fullPathThumbnail,
        },
        {
          name: `og:image`,
          property: `og:image`,
          content: fullPathThumbnail,
        },
        {
          property: `og:image:width`,
          content: `1280`,
        },
        {
          property: `og:image:height`,
          content: `720`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:src`,
          content: fullPathThumbnail,
        },
        {
          name: `twitter:image`,
          content: fullPathThumbnail,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    ></Helmet>
  )
}

export default SEO
